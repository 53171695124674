// Quasar Sass (& SCSS) Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the Sass/SCSS variables found in Quasar's source Sass/SCSS files.

// Check documentation for full list of Quasar variables

// Your own variables (that are declared here) and Quasar's own
// ones will be available out of the box in your .vue/.scss/.sass files

// It's highly recommended to change the default colors
// to match your app's branding.
// Tip: Use the "Theme Builder" on Quasar's documentation website.

$primary   : #1976D2
$secondary : #26A69A
$accent    : #9C27B0

$dark      : #1D1D1D

// for rating
$blue-grey-12: rgba(3, 112, 247, 0.15)
$blue-grey-13: rgb(3, 112, 247, 0.5)
$blue-grey-14: rgb(3, 112, 247, 1)
// for input background
$grey-1: #ECECEE
// for text-color
$grey-13: #4D4F53

$positive  : #21BA45
$negative  : #C10015
$info      : #31CCEC
$warning   : #F2C037
